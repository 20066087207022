<template>
    <div class="s-inner">
        <h3 v-if="state.isPreview === 'Y'">이력서 등록</h3>
        <div id="app" class="sub-resume-write">
        <div>
            <h4>인적사항</h4>
            <div class="personal-data">
                <div class="photo-box">
                    <div class="img">
                        <img v-if="!!state.attachments.file" :src="state.attachments.file">
                    </div>
                    <a v-if="state.isPreview === 'Y'" href="javascript:void(0)" title="사진등록/수정" class="btn-box">사진등록/수정</a>
                </div>
                <div class="form-box">
                    <div>
                        <Dropdown v-model="state.applicantInfo.APCT_PT_CD" :options="state.selectBox.recruitmentPart" optionLabel="CodeName" optionValue="CodeVal" placeholder="지원분야" :disabled="state.isDisable" class="w-200"/>
                        <InputText v-model="state.applicantInfo.APCTP_KR_NM" type="text" placeholder="성명" disabled class="w-200"/>
                        <InputText v-model="state.applicantInfo.APCTP_EN_NM" type="text" placeholder="영문명" :disabled="state.isDisable" class="w-200"/>
                        <InputText v-model="state.applicantInfo.BRTH_DA" type="text" placeholder="생년월일" disabled class="w-150"/>
                        <!-- <InputText v-model="state.applicantInfo.NATION_NM" type="text" placeholder="국적" disabled class="w-150"/> -->
                        <Dropdown v-model="state.applicantInfo.NATION_NM" :options="state.selectBox.nation" optionLabel="CodeName" optionValue="CodeVal" placeholder="국적" :disabled="state.isDisable" class="w-150"/> 
                    </div>
                    <div>
                        <InputText v-model="state.applicantInfo.PHONE_NO" type="text" placeholder="연락처" disabled class="w-225"/>
                        <InputText v-model="state.applicantInfo.EMAIL_ADR" type="text" placeholder="이메일 주소" :disabled="state.isDisable" class="w-225"/>
                        <Dropdown v-model="state.applicantInfo.GNDR_CD" :options="state.selectBox.gender" optionLabel="CodeName" optionValue="CodeVal" placeholder="성별선택" :disabled="state.isDisable" class="w-150"/> 
                        <Dropdown v-model="state.applicantInfo.VETERAN_YN" :options="state.selectBox.patriot" optionLabel="CodeName" optionValue="CodeVal" placeholder="보훈대상여부" :disabled="state.isDisable" class="w-150"/>
                        <Dropdown v-model="state.applicantInfo.HANDC_YN" :options="state.selectBox.handicap" optionLabel="CodeName" optionValue="CodeVal" placeholder="장애대상여부" :disabled="state.isDisable" class="w-150"/>
                    </div>
                    <div>
                        <div class="zip-box w-225">
                            <InputText v-model="state.applicantInfo.ZCD" type="text" placeholder="우편번호 검색" :disabled="state.isDisable"/>
                            <a href="javascript:void(0)" title="검색" class="sch-btn"><span class="text_hidden">검색</span></a>
                        </div>
                        <InputText v-model="state.applicantInfo.ADR" type="text" placeholder="주소" :disabled="state.isDisable" class="w-690"/>
                    </div>
                    <div class="mb10">
                        <InputText v-if="state.formInfo.isShowCurntSalAmt" v-model="state.applicantInfo.CURNT_SAL_AMT" type="text" placeholder="현재 연봉(만원)" :disabled="state.isDisable" class="w-225"/>
                        <InputText v-model="state.applicantInfo.HBB_ABILT_CONT" type="text" placeholder="취미 및 특기" :disabled="state.isDisable" class="w-flex1"/>
                    </div>
                    <div>
                        <InputText v-if="state.formInfo.isShowHopeSalAmt" v-model="state.applicantInfo.HOPE_SAL_AMT" type="text" placeholder="희망연봉(만원)" :disabled="state.isDisable" class="w-225"/>
                        <InputText v-if="state.formInfo.isShowPsnltyCont" v-model="state.applicantInfo.PSNLTY_CONT" type="text" placeholder="성격상 장단점" :disabled="state.isDisable" class="w-flex1"/>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="state.formInfo.isShowAmy">
            <h4>병역사항</h4>

            <div class="form-box">
                <div>
                    <Dropdown v-model="state.applicantInfo.AMY_PFRM_CD" :options="state.selectBox.militery" optionLabel="CodeName" optionValue="CodeVal" placeholder="구분" :disabled="state.isDisable"/>
                    <Dropdown v-model="state.applicantInfo.AMY_DIVCD_NM" :options="state.selectBox.gunByul" optionLabel="CodeName" optionValue="CodeVal" placeholder="군별" :disabled="state.isDisable" class="w-120"/>
                    <Dropdown v-model="state.applicantInfo.AMY_RNK_NM" :options="state.selectBox.gyeGb" optionLabel="CodeName" optionValue="CodeVal" placeholder="계급" :disabled="state.isDisable" class="w-120"/>
                    <div class="date-box w-320">
                        <div>
                            <input id="icon" type="date" v-model="state.applicantInfo.AMY_ST_DA" class="p-inputtext p-component" style="width:150px;" :disabled="state.isDisable" max="9999-12-31" />
                        </div>
                        <span class="txt">~</span>
                        <div>
                            <input id="icon" type="date" v-model="state.applicantInfo.AMY_END_DA" class="p-inputtext p-component" style="width:150px;" :disabled="state.isDisable" max="9999-12-31" />
                        </div>
                    </div>
                    <InputText v-model="state.applicantInfo.AMY_BRCH_NM" type="text" placeholder="병과" :disabled="state.isDisable" class="w-150"/>
                    <InputText v-model="state.applicantInfo.CMSN_DIV_CONT" type="text" placeholder="임관구분(장교대상)" :disabled="state.isDisable" class="w-190"/>
                    <InputText v-model="state.applicantInfo.EXMT_RSON_CONT" type="text" placeholder="면제사유" :disabled="state.isDisable" class="w-150"/>
                </div>
            </div>

        </div>

        <div v-if="state.formInfo.isShowSchool">
            <div class="sub-tit-wrap1">
                <h4>학력사항</h4>
                <div v-if="state.isPreview === 'Y'">
                    <a href="javascript:void(0)" title="추가" class="btn-box">추가</a>
                </div>
            </div>

            <div class="delete-box" v-if="state.isPreview === 'Y'">
                <!-- 학력사항 추가 섹션 -->
                <div>
                    <div class="form-box">
                        <div class="mb5">
                            <Dropdown :options="state.selectBox.schoolKind" optionLabel="CodeName" optionValue="CodeVal" placeholder="학교구분" :disabled="state.isDisable" class="w-150"/>
                            <Dropdown :options="state.selectBox.place" optionLabel="CodeName" optionValue="CodeVal" placeholder="소재지" :disabled="state.isDisable" class="w-150"/>
                            <InputText type="text" placeholder="학교명" class="w-225"/>
                            <div class="date-box w-470">
                                <div>
                                    <input id="icon" type="month" :show-icon="true" :disabled="state.isDisable" placeholder="입학년월" class="p-inputtext p-component" style="width:180px;"  max="9999-12" />
                                </div>
                                <span class="txt">~</span>
                                <div>
                                    <input id="icon" type="month" :show-icon="true" :disabled="state.isDisable" placeholder="졸업년월" class="p-inputtext p-component" style="width:180px;" max="9999-12" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <Dropdown :options="state.selectBox.graduation" optionLabel="CodeName" optionValue="CodeVal" placeholder="소재지" :disabled="state.isDisable" class="w-150"/>
                            <InputText type="text" placeholder="전공" :disabled="state.isDisable" class="w-225"/>
                            <InputText type="text" placeholder="학위" :disabled="state.isDisable" class="w-225"/>
                            <InputText type="text" placeholder="학점(4.5만점)" :disabled="state.isDisable" class="w-225"/>
                        </div>
                    </div>
                    <a href="javascript:void(0)" title="삭제" class="btn-box red">삭제</a>
                </div>
                <!-- //학력사항 추가 섹션 -->
            </div>

            <div class="delete-box" v-else v-for="school of state.schoolInfo" :key="school" style="padding-bottom:30px;">
                <!-- 학력사항 추가 섹션 -->
                <div>
                    <div class="form-box">
                        <div class="mb5">
                            <Dropdown v-model="school.SCHL_DIV_CD" :options="state.selectBox.schoolKind" optionLabel="CodeName" optionValue="CodeVal" placeholder="학교구분" :disabled="state.isDisable" class="w-150"/>
                            <Dropdown v-model="school.SCHL_ARA" :options="state.selectBox.place" optionLabel="CodeName" optionValue="CodeVal" placeholder="소재지" :disabled="state.isDisable" class="w-150"/>
                            <InputText v-model="school.SCHL_NM" type="text" placeholder="학교명" :disabled="state.isDisable" class="w-225"/>
                            <div class="date-box w-470">
                                <div>
                                    <input id="icon" type="month" v-model="school.SCHL_ST_YM" placeholder="입학년월" class="p-inputtext p-component" style="width:180px;" :disabled="state.isDisable" max="9999-12" />
                                </div>
                            <span class="txt">~</span>
                                <div>
                                    <input id="icon" type="month" v-model="school.SCHL_END_YM" placeholder="졸업년월" class="p-inputtext p-component" style="width:180px;" :disabled="state.isDisable" max="9999-12" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <Dropdown v-model="school.GRADU_DIV_CD" :options="state.selectBox.graduation" optionLabel="CodeName" optionValue="CodeVal" placeholder="졸업구분" :disabled="state.isDisable" class="w-150"/>
                            <InputText v-model="school.SCHL_MAJR_NM" type="text" placeholder="전공" :disabled="state.isDisable" class="w-225"/>
                            <InputText v-model="school.SCHL_DGR_NM" type="text" placeholder="학위" :disabled="state.isDisable" class="w-225"/>
                            <InputText v-model="school.SCHL_SCOR" type="text" placeholder="학점(4.5만점)" :disabled="state.isDisable" class="w-225"/>
                        </div>
                    </div>
                </div>
                <!-- //학력사항 추가 섹션 -->
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { recruitCommon } from '@/modules/recruit/recruit-common.js';
import { common } from '@/modules/common/common.js';
export default {
    props: [
        // 양식 정보
        'formInfo'

        // 미리보기 여부
        , 'isPreview'

        // 지원자 정보
        , 'recInfo'
    ]
    , setup(props){
        // 스토어
        const store = useStore();

        // state (변수)
        const state = reactive({

            // 양식 정보
            formInfo: !!props.formInfo ? props.formInfo : []

            // 미리보기 여부
            , isPreview: !!props.isPreview ? props.isPreview : 'N'

            // 지원자 정보
            , recInfo: !!props.recInfo ? props.recInfo : {}

            // 입사지원서 정보
            , applicantInfo: new Object()

            // 입사지원서 학력 정보
            , schoolInfo: new Array()

            , selectBox: {
                recruitmentPart: new Array()
                , militery: new Array()
                , gunByul: new Array()
                , gyeGb: new Array()
                , schoolKind: new Array()
                , place: new Array()
                , gender: new Array()
                , patriot: new Array()
                , handicap: new Array()
                , nation: new Array()
                , graduation: new Array()
            }

            // 변경가능 여부
            , isDisable: false

            , attachments: {
                file: null,
            }
        });

        // 메소드
        const methods = {

            // 입사지원서 상세 조회
            getApplicantInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                let info = JSON.stringify(state.recInfo)

                // 상세화면 formNo로 데이터테이블 세팅
                recruitCommon.getApplicantInfo(info).then(result => {

                    state.applicantInfo = result.Table.rows[0];
                    state.schoolInfo = result.Table1.rows

                    recruitCommon.getEnrollCrtifForeignFile(info).then(result => {
                        
                        let data = result.Table2.rows;

                        if(data.length > 0){
                            data.forEach(photo => {
                                if(photo.ID_PIC_YN == 'Y'){
                                    state.attachments.file = `/AttachFiles/${photo.ATCH_FILE_PTH_NM}`
                                }
                            })
                        }           
                    })
                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            }
        };

        onMounted(() => {

            if(state.isPreview != 'Y'){

                // 지원분야 조회
                common.getSystemCodeInfo('CODEINFO','RC001000').then(result => state.selectBox.recruitmentPart = result);
                common.getSystemCodeInfo('CODEINFO','CD005000').then(result => state.selectBox.gender = result);
                // 상세화면 formNo로 데이터테이블 세팅
                common.getSystemCodeInfo('CODEINFO','CD006000').then(result => state.selectBox.patriot = result);
                common.getSystemCodeInfo('CODEINFO','CD002000').then(result => state.selectBox.handicap = result);
                // 국가 구분 조회
                common.getSystemCodeInfo('CODEINFO','RC009000').then(result => state.selectBox.nation = result);
                // 병역사항 구분 조회
                common.getSystemCodeInfo('CODEINFO','CD001000').then(result => state.selectBox.militery = result);
                // 병역사항 군별 조회
                common.getSystemCodeInfo('CODEINFO','RC002000').then(result => state.selectBox.gunByul = result);
                // 병역사항 계급 조회
                common.getSystemCodeInfo('CODEINFO','RC003000').then(result => state.selectBox.gyeGb = result);

                // 학력사항 학교구분 조회
                common.getSystemCodeInfo('CODEINFO','CD004000').then(result => state.selectBox.schoolKind = result);
                // 학력사항 소재지 조회
                common.getSystemCodeInfo('CODEINFO','CD008000').then(result => state.selectBox.place = result);
                // 학력사항 졸업여부 조회
                common.getSystemCodeInfo('CODEINFO','RC006000').then(result => state.selectBox.graduation = result);

                // 입사지원서 정보 조회
                methods.getApplicantInfo();

                // 변경가능여부
                state.isDisable = true
            }
        });

        return {
            store
            , methods
            , state
            , props
            , recruitCommon
            , common
        }
    }
}
</script>