<template>
    <div class="s-inner">
        <h3 v-if="state.isPreview === 'Y'">자기소개서 등록</h3>
        <h3 v-else style="padding-top:70px;">자기소개서</h3>
        <div id="app" class="sub-resume-write sub-letter-write" v-if="state.formInfo.isShowCarrerEssay === true">
            <div>
                <div class="sub-tit-wrap1">
                    <h4>지원동기</h4>
                    <div>
                        <p class="typing">{{ state.essayCarrerInfo.LEN_REASON_CONT == null ? 0 : state.essayCarrerInfo.LEN_REASON_CONT }} / 500</p>
                    </div>
                </div>            
    
                <Textarea v-model="state.essayCarrerInfo.REASON_CONT" style="height: calc(258px); overflow: hidden;" :auto-resize="true" placeholder="지원동기를 기술해주세요. (※ 10줄 이내, 500자 이내로 작성)" rows="9" :disabled="state.isDisable"/>
            </div>
            <div>
                <div class="sub-tit-wrap1">
                    <h4>성격(장단점) 및 생활신조</h4>
                    <div>
                        <p class="typing">{{ state.essayCarrerInfo.LEN_PSNL_CONT == null ? 0 : state.essayCarrerInfo.LEN_PSNL_CONT }} / 500</p>
                    </div>
                </div>            
    
                <Textarea v-model="state.essayCarrerInfo.PSNL_CONT" style="height: calc(258px); overflow: hidden;" :auto-resize="true" placeholder="성격(장단점) 및 생활신조를 기술해주세요. (※ 10줄 이내, 500자 이내로 작성)" rows="9" :disabled="state.isDisable"/>
            </div>
            <div>
                <div class="sub-tit-wrap1">
                    <h4>경력사항 또는 성장과정</h4>
                    <div>
                        <p class="typing">{{ state.essayCarrerInfo.LEN_CARR_CONT == null ? 0 : state.essayCarrerInfo.LEN_CARR_CONT }} / 500</p>
                    </div>
                </div>            
    
                <Textarea v-model="state.essayCarrerInfo.CARR_CONT" style="height: calc(258px); overflow: hidden;" :auto-resize="true" placeholder="경력사항 또는 성장과정을 기술해주세요. (※ 10줄 이내, 500자 이내로 작성)" rows="9" :disabled="state.isDisable"/>
            </div>
            <div>
                <div class="sub-tit-wrap1">
                    <h4>희망업무</h4>
                    <div>
                        <p class="typing">{{ state.essayCarrerInfo.LEN_HOPE_WK_CONT == null ? 0 : state.essayCarrerInfo.LEN_HOPE_WK_CONT }} / 500</p>
                    </div>
                </div>            
    
                <Textarea v-model="state.essayCarrerInfo.HOPE_WK_CONT" style="height: calc(258px); overflow: hidden;" :auto-resize="true" placeholder="희망업무를 기술해주세요. (※ 10줄 이내, 500자 이내로 작성)" rows="9" :disabled="state.isDisable"/>
            </div>
            <div>
                <div class="sub-tit-wrap1">
                    <h4>입사 후 포부</h4>
                    <div>
                        <p class="typing">{{ state.essayCarrerInfo.LEN_PLAN_CONT == null ? 0 : state.essayCarrerInfo.LEN_PLAN_CONT }} / 500</p>
                    </div>
                </div>            
    
                <Textarea v-model="state.essayCarrerInfo.PLAN_CONT" style="height: calc(258px); overflow: hidden;" :auto-resize="true" placeholder="입사 후 포부를 기술해주세요. (※ 10줄 이내, 500자 이내로 작성)" rows="9" :disabled="state.isDisable"/>
            </div>
        </div>

        <div id="app" class="sub-resume-write sub-letter-write" v-else>
            <div>
                <div class="sub-tit-wrap1">
                    <h4>지원 동기 및 입사 후 이루고 싶은 최종 목표를 기술해 주십시오. (500자 이내)</h4>
                    <div>
                        <p class="typing">{{ state.essayNewInfo.LEN_REASON_CONT == null ? 0 : state.essayNewInfo.LEN_REASON_CONT }} / 500</p>
                    </div>
                </div>            
    
                <Textarea v-model="state.essayNewInfo.REASON_CONT" style="height: calc(258px); overflow: hidden;" :auto-resize="true" rows="9" :disabled="state.isDisable"/>
            </div>
            <div>
                <div class="sub-tit-wrap1">
                    <h4>본인을 가장 잘 표현할 수 있는 세 단어를 제시하고, 그 이유를 구체적인 사례를 들어 설명해 주십시오. (700자 이내)</h4>
                    <div>
                        <p class="typing">{{ state.essayNewInfo.LEN_GR_HIST_CONT == null ? 0 : state.essayNewInfo.LEN_GR_HIST_CONT }} / 700</p>
                    </div>
                </div>            
    
                <Textarea v-model="state.essayNewInfo.GR_HIST_CONT" style="height: calc(258px); overflow: hidden;" :auto-resize="true" rows="9" :disabled="state.isDisable"/>
            </div>
            <div>
                <div class="sub-tit-wrap1">
                    <h4>지원하는 직무와 관련하여 본인이 가진 역량에 대하여 기술해 주십시오.(지원직무 관련 경험, 지식습득, 본인의 강점 역량과 이를 키우기 위한 노력 또는 성장가능성 등을 중심으로 서술) (700자 이내)</h4>
                    <div>
                        <p class="typing">{{ state.essayNewInfo.LEN_ABILITY_CONT == null ? 0 : state.essayNewInfo.LEN_ABILITY_CONT }} / 700</p>
                    </div>
                </div>            
    
                <Textarea v-model="state.essayNewInfo.ABILITY_CONT" style="height: calc(258px); overflow: hidden;" :auto-resize="true" rows="9" :disabled="state.isDisable"/>
            </div>
            <div>
                <div class="sub-tit-wrap1">
                    <h4>커뮤니케이션/협업을 잘하는 사람은 어떤 사람이라고 생각하는지 간략히 정의하고, 본인이 이러한 역량을 발휘했던 사례를 서술해 주십시오. (700자 이내)</h4>
                    <div>
                        <p class="typing">{{ state.essayNewInfo.LEN_ISSUE_CONT == null ? 0 : state.essayNewInfo.LEN_ISSUE_CONT }} / 700</p>
                    </div>
                </div>            
    
                <Textarea v-model="state.essayNewInfo.ISSUE_CONT" style="height: calc(258px); overflow: hidden;" :auto-resize="true" rows="9" :disabled="state.isDisable"/>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { recruitCommon } from '@/modules/recruit/recruit-common.js';
import { common } from '@/modules/common/common.js';
export default {
    props: [
        // 양식 정보
        'formInfo'

        // 미리보기 여부
        , 'isPreview'

        // 지원자 정보
        , 'recInfo'
    ]
    , setup(props){
        // 스토어
        const store = useStore();

        // state (변수)
        const state = reactive({

            // 양식 정보
            formInfo: !!props.formInfo ? props.formInfo : []

            // 미리보기 여부
            , isPreview: !!props.isPreview ? props.isPreview : 'N'

            // 지원자 정보
            , recInfo: !!props.recInfo ? props.recInfo : {}

            // 자기소개서(신입) 정보
            , essayNewInfo: new Object()

            // 자기소개서(경력) 정보
            , essayCarrerInfo: new Object()

            // 변경가능 여부
            , isDisable: false
        });

        // 메소드
        const methods = {

            // 입사지원서 자기소개서 조회
            getEnrollEssay: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                //state.recInfo.CRR_DIV_CD = state.formInfo.isShowCarrerEssay == true ? 'RC005305' : 'RC005310'

                let info = JSON.stringify(state.recInfo)

                recruitCommon.getEnrollEssay(info).then(result => {

                    if(result.Table.rows[0]['CRR_DIV_CD'] == 'RC005305') state.formInfo.isShowCarrerEssay = true
                    else state.formInfo.isShowCarrerEssay = false

                    if (state.formInfo.isShowCarrerEssay == true && !!result.Table) state.essayCarrerInfo = result.Table.rows[0];
                    else if (!!result.Table) state.essayNewInfo = result.Table.rows[0];

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            }
        };

        onMounted(() => {

            if(state.isPreview != 'Y'){
                
                // 입사지원서 자기소개서 조회
                methods.getEnrollEssay();

                // 변경가능여부
                state.isDisable = true
            }
        });

        return {
            store
            , methods
            , state
            , props
            , recruitCommon
            , common
        }
    }
}
</script>