import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 *  관리자 화면 채용 - 입사지원서 양식 상세관리 기능 클래스
 */
class RecruitCommon {

    /**
     * 생성자
     */
    constructor() {
        this.formNo = 1;
        this.formDivCd = 'RC005000';
        this.formContCd = 'RC005010';
        this.useYn = 'Y';
    }

    /**
     * 입사지원서 상세 조회
     * @param {any} formNo
     */
    getApplicantInfo(info) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetEnrollApplicantInfo';
            request.parameters.dic = info;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if (!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };

    /**
     * 입사지원서 경력 조회
     * @param {any} formNo
     */
    getEnrollCareer(info) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetEnrollCareer';
            request.parameters.dic = info;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if (!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };
    
    /**
     * 입사지원서 외국어, 자격증, 첨부파일 조회
     * @param {any} formNo
     */
    getEnrollCrtifForeignFile(info) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetEnrollCrtifForeignFile';
            request.parameters.dic = info;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if (!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };

    /**
     * 입사지원서 외국어, 자격증, 첨부파일 조회
     * @param {any} formNo
     */
    getEnrollEssay(info) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetEnrollEssay';
            request.parameters.dic = info;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if (!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };

    /**
     * 입사지원서 양식 저장
     * @param {string} form 양식마스터 정보
     * @param {string} detail 양식상세 정보
     */
    saveFormInfo(form, detail) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'SaveFormInfo';
            request.parameters.form = form;
            request.parameters.detail = detail;

            service.execute(request).then(response => {
                resolve(response.data)
            }).catch(error => reject(error));
        });
    }
}

export const recruitCommon = new RecruitCommon();