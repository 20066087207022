<template>
    <div class="s-inner">
        <h3 v-if="state.isPreview === 'Y'">어학/자격/기타 사항 등록</h3>
        <div v-else style="padding:40px; border-top :1px solid #d3d3d3"></div>
        <div id="app" class="sub-resume-write">
            <div v-if="state.formInfo.isShowCertification">
            <div class="sub-tit-wrap1">
                <h4>자격증 및 수상내역</h4>
                <div v-if="state.isPreview === 'Y'">
                    <a href="javascript:void(0)" title="추가" class="btn-box">추가</a>
                </div>
            </div>

            <div class="delete-box" v-if="state.isPreview === 'Y'">
                <!-- 자격증 및 수상내역 추가 섹션 -->
                <div>
                    <div class="form-box">
                        <div>
                            <InputText type="text" placeholder="자격증 및 수상내역" class="w-505"/>
                            <InputText type="text" placeholder="등급" class="w-150"/>
                            <InputText type="text" placeholder="발급기관" class="w-190"/>
                            <input class="p-inputtext p-component w-200" type="date" placeholder="취득/응시일" :disabled="state.isDisable" :show-icon="true" >
                            <!-- <Calendar input-id="icon" :show-icon="true" placeholder="취득일" :disabled="state.isDisable" class="w-190"/> -->
                        </div>
                    </div>
                    <a href="javascript:void(0)" title="삭제" class="btn-box red">삭제</a>
                </div>
                <!-- //자격증 및 수상내역 추가 섹션 -->
            </div>

            <div class="delete-box" v-else v-for="certification of state.certificationInfo" :key="certification" style="padding-bottom:30px;">
                <!-- 자격증 및 수상내역 추가 섹션 -->
                <div>
                    <div class="form-box">
                        <div>
                            <InputText v-model="certification.CRTIF_AWRD_CONT" type="text" placeholder="자격증 및 수상내역" :disabled="state.isDisable" class="w-505"/>
                            <InputText v-model="certification.CRTIF_DGR_NM" type="text" placeholder="등급" :disabled="state.isDisable" class="w-150"/>
                            <InputText v-model="certification.CRTIF_ORG_NM" type="text" placeholder="발급기관" :disabled="state.isDisable" class="w-190"/>
                            <input class="p-inputtext p-component w-200" v-model="certification.CRTIF_GET_DA" type="date" placeholder="취득/응시일" :disabled="state.isDisable" :show-icon="!state.isDisable" >
                            <!-- <Calendar v-model="certification.CRTIF_GET_DA" input-id="icon" :show-icon="!state.isDisable" placeholder="취득일" :disabled="state.isDisable" class="w-190"/> -->
                        </div>
                    </div>
                </div>
                <!-- //자격증 및 수상내역 추가 섹션 -->
            </div>
        </div>

        <div v-if="state.formInfo.isShowForeignLang">
            <div class="sub-tit-wrap1">
                <h4>외국어</h4>
                <div v-if="state.isPreview === 'Y'">
                    <a href="javascript:void(0)" title="추가" class="btn-box">추가</a>
                </div>
            </div>

            <div class="delete-box" v-if="state.isPreview === 'Y'">
                <!-- 외국어 추가 섹션 -->
                <div>
                    <div class="form-box">
                        <div>
                            <Dropdown option-label="name" option-value="code" placeholder="외국어 시험명" class="w-200"/>
                            <InputText type="text" placeholder="기타" class="w-200"/>
                            <InputText type="text" placeholder="점수(등급)" class="w-150"/>
                            <div>
                                <input id="icon" type="date" placeholder="취득일" class="p-inputtext p-component" style="width:150px;" />
                            </div>
                            <!-- <Calendar input-id="icon" :show-icon="!state.isDisable" placeholder="취득/응시일" :disabled="state.isDisable" class="w-190"/> -->
                            <Dropdown option-label="name" option-value="code" placeholder="시험지" class="w-150"/>
                        </div>
                    </div>
                    <a href="javascript:void(0)" title="삭제" class="btn-box red">삭제</a>
                </div>
                <!-- //외국어 추가 섹션 -->
            </div>

            <div class="delete-box" v-else v-for="foreignLangTest of state.foreignLangTestInfo" :key="foreignLangTest" style="padding-bottom:30px;">
                <!-- 외국어 추가 섹션 -->
                <div>
                    <div class="form-box">
                        <div>
                            <Dropdown v-model="foreignLangTest.FRGN_DIV_CD" :options="state.selectBox.LangCode" optionLabel="CodeName" optionValue="CodeVal" placeholder="외국어 시험명" :disabled="state.isDisable" class="w-200"/>
                            <InputText v-model="foreignLangTest.FRGN_ECT_NM" type="text" placeholder="기타" :disabled="state.isDisable" class="w-200"/>
                            <InputText v-model="foreignLangTest.FRGN_SCOR" type="text" placeholder="점수(등급)" :disabled="state.isDisable" class="w-150"/>
                            <div>
                                <input id="icon" type="date" placeholder="취득일" v-model="foreignLangTest.FRGN_TST_DA" :show-icon="!state.isDisable" :disabled="state.isDisable" class="p-inputtext p-component" style="width:150px;" />
                            </div>
                            <!-- <Calendar v-model="foreignLangTest.FRGN_TST_DA" input-id="icon" :show-icon="!state.isDisable" placeholder="취득/응시일" :disabled="state.isDisable" class="w-190"/> -->
                            <Dropdown v-model="foreignLangTest.FRGN_TST_ARA" :options="state.selectBox.testArea" optionLabel="CodeName" optionValue="CodeVal" placeholder="시험지" :disabled="state.isDisable" class="w-150"/>
                        </div>
                    </div>
                </div>
                <!-- //외국어 추가 섹션 -->
            </div>
        </div>

        <div v-if="state.formInfo.isShowFile">
            <div class="sub-tit-wrap1">
                <h4>파일 첨부</h4>
            </div>
            <ul class="file-list mt10 a-hover">
                <li v-for="file of state.fileInfo" :key="file">
                    <p v-if="file.ID_PIC_YN == 'N'" @click="eventHandler.downloadFile(file)"><a href="javascript:;">{{ file.ATCH_FILE_NM }}</a></p>
                </li>
            </ul>
        </div>
        </div>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { recruitCommon } from '@/modules/recruit/recruit-common.js';
import { common } from '@/modules/common/common.js';
import axios from 'axios';
import alertConfirmManager from '@/utils/alert-confirm-manager';
export default {
    props: [
        // 양식 정보
        'formInfo'

        // 미리보기 여부
        , 'isPreview'

        // 지원자 정보
        , 'recInfo'
    ]
    , setup(props){
        // 스토어
        const store = useStore();

        // state (변수)
        const state = reactive({

            // 양식 정보
            formInfo: !!props.formInfo ? props.formInfo : []

            // 미리보기 여부
            , isPreview: !!props.isPreview ? props.isPreview : 'N'

            // 지원자 정보
            , recInfo: !!props.recInfo ? props.recInfo : {}

            // 자격증 정보
            , certificationInfo: new Array()

            // 외국어 정보
            , foreignLangTestInfo: new Array()

            // 파일 정보
            , fileInfo: new Array()

            , selectBox: {
                testArea: new Array(),
                LangCode: new Array(),
            }

            // 변경가능 여부
            , isDisable: false
        });

        // 메소드
        const methods = {

            // 입사지원서 외국어, 자격증, 첨부파일 조회
            getEnrollCrtifForeignFile: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                let info = JSON.stringify(state.recInfo)

                recruitCommon.getEnrollCrtifForeignFile(info).then(result => {

                    state.certificationInfo = result.Table.rows;
                    state.foreignLangTestInfo = result.Table1.rows;
                    state.fileInfo = result.Table2.rows;
                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            }
        };

        const eventHandler = {
            /**
             * 파일 다운로드 실행
             * @param {any} file : 파일정보
             */
            downloadFile: file => {
                // 진행 토스트
                alertConfirmManager.toast.fileDownloading();

                const url = process.env.VUE_APP_FILE_UPDOWNLOAD_HANDLER_BASE_DIR + store.getters.fileDownloadUrl;
                const formData = new FormData();

                formData.append("VIRDIR", file.ATCH_FILE_PTH_NM);

                axios.post(url, formData, {
                    responseType: 'blob',
                }).then(response => {

                    // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환
                    const blob = new Blob([response.data]);

                    // blob을 사용해 객체 URL을 생성
                    const fileUrl = window.URL.createObjectURL(blob);

                    // 다운로드 파일명
                    const fileName = response.headers["content-disposition"].split("filename=")[1].replace(/"/g, "");

                    // blob 객체 URL을 설정할 링크 생성
                    const link = document.createElement('a');
                    link.href = fileUrl;
                    link.style.display = 'none';
                    link.download = decodeURIComponent(fileName);

                    // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    // 다운로드가 끝난 리소스(객체 URL)를 해제
                    window.URL.revokeObjectURL(fileUrl);

                    // 완료 토스트
                    alertConfirmManager.toast.fileDownloaded();
                });
            }
        };

        onMounted(() => {

            if(state.isPreview != 'Y'){

                // 외국어 시험명 조회
                common.getSystemCodeInfo('CODEINFO','RC008000').then(result => state.selectBox.LangCode = result);
                // 외국어시험지역 조회
                common.getSystemCodeInfo('CODEINFO','RC007000').then(result => state.selectBox.testArea = result);

                // 입사지원서 정보 조회
                methods.getEnrollCrtifForeignFile();

                // 변경가능여부
                state.isDisable = true
            }
        });

        return {
            store
            , methods
            , eventHandler
            , state
            , props
            , recruitCommon
            , common
        }
    }
}
</script>