<template>
    <div class="s-inner">
        <h3 v-if="state.isPreview === 'Y'">경력서 등록</h3>
        <div v-else style="padding:40px; border-top :1px solid #d3d3d3"></div>
        <div id="app" class="sub-resume-write">
        <div>
            <div class="sub-tit-wrap1">
                <h4>경력사항</h4>
                <div v-if="state.isPreview === 'Y'">
                    <a href="javascript:void(0)" title="추가" class="btn-box">추가</a>
                </div>
            </div>            

            <div class="delete-box case2" v-if="state.isPreview === 'Y'">
                <!-- 경력사항 추가 섹션 -->
                <div>
                    <div class="form-box">
                        <div class="mb5">
                            <InputText type="text" placeholder="회사명" class="w-240"/>
                            <Dropdown placeholder="소재지" class="w-150"/>
                            <InputText type="text" placeholder="부서명" style="width:133px;"/>
                            <InputText type="text" placeholder="직위" class="w-120"/>
                            <div class="date-box w-320">
                                <div>
                                    <input id="icon" type="date" :show-icon="true" class="p-inputtext p-component" style="width:150px;" max="9999-12-31" />
                                </div>
                                <span class="txt">~</span>
                                <div>
                                    <input id="icon" type="date" :show-icon="true" class="p-inputtext p-component" style="width:150px;" max="9999-12-31" />
                                </div>
                            </div>
                            
                            <InputText v-if="state.isNew == true" class="w-190" type="text" placeholder="업무내용" maxlength="20"/>
                        </div>
                        <div>
                            <Textarea v-if="state.isNew == false" style="height: calc(258px); overflow: hidden;" :auto-resize="true" placeholder="직장에서 맡았던 업무내용 등을 구체적으로 기술해주세요.(※ 800자 이내로 작성)" rows="9" cols="30" class="wi-100"/>
                        </div>
                    </div>
                    <a href="javascript:void(0)" title="삭제" class="btn-box red">삭제</a>
                </div>
                <!-- 경력사항 추가 섹션 -->
            </div>

            <div class="delete-box case2" v-else v-for="carrer of state.carerrInfo" :key="carrer" style="padding-bottom:70px;">
                <!-- 경력사항 추가 섹션 -->
                <div>
                    <div class="form-box">
                        <div class="mb5">
                            <InputText v-model="carrer.COMP_NM" type="text" placeholder="회사명" :disabled="state.isDisable" class="w-240"/>
                            <Dropdown v-model="carrer.COMP_ARA" :options="state.selectBox.carrerArea" optionLabel="CodeName" optionValue="CodeVal" placeholder="소재지" :disabled="state.isDisable" class="w-120"/>
                            <InputText v-model="carrer.COMP_PT_NM" type="text" placeholder="부서명" :disabled="state.isDisable" class="w-190"/>
                            <InputText v-model="carrer.COMP_RNK_NM" type="text" placeholder="직위" :disabled="state.isDisable" class="w-120"/>
                            <div class="date-box w-320">
                                <div>
                                    <input id="icon" type="date" v-model="carrer.COMP_ST_DA" :show-icon="false" :disabled="state.isDisable" class="p-inputtext p-component" style="width:150px;" max="9999-12-31" />
                                </div>
                                <span class="txt">~</span>
                                <div>
                                    <input id="icon" type="date" v-model="carrer.COMP_END_DA" :show-icon="false" :disabled="state.isDisable" class="p-inputtext p-component" style="width:150px;" max="9999-12-31" />
                                </div>
                            </div>
                            <InputText v-if="state.isNew == true" class="w-190" type="text" v-model="carrer.COMP_WK_CONT" placeholder="업무내용" maxlength="20"/>
                        </div>
                        <div>
                            <Textarea v-if="state.isNew == false" v-model="carrer.COMP_WK_CONT" style="height: auto; resize: vertical; overflow: auto;" placeholder="직장에서 맡았던 업무내용 등을 구체적으로 기술해주세요.(※ 800자 이내로 작성)" rows="9" cols="30" :disabled="state.isDisable" class="wi-100"/>
                        </div>
                    </div>
                </div>
                <!-- 경력사항 추가 섹션 -->
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { recruitCommon } from '@/modules/recruit/recruit-common.js';
import { common } from '@/modules/common/common.js';
export default {
    props: [
        // 양식 정보
        'formInfo'

        // 미리보기 여부
        , 'isPreview'

        // 지원자 정보
        , 'recInfo'
        
        // 신입/경력 여부
        , 'isNew'
    ]
    , setup(props){
        // 스토어
        const store = useStore();

        // state (변수)
        const state = reactive({

            // 양식 정보
            formInfo: !!props.formInfo ? props.formInfo : []

            // 미리보기 여부
            , isPreview: !!props.isPreview ? props.isPreview : 'N'

            // 지원자 정보
            , recInfo: !!props.recInfo ? props.recInfo : {}

            , isNew: !!props.isNew ? props.isNew : false

            // 경력 정보
            , carerrInfo: new Array()

            , selectBox: {
                carrerArea: new Array()
            }

            // 변경가능 여부
            , isDisable: false
        });

        // 메소드
        const methods = {

            // 입사지원서 경력 조회
            getEnrollCareer: () => {
                
                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                let info = JSON.stringify(state.recInfo)

                recruitCommon.getEnrollCareer(info).then(result => {

                    state.carerrInfo = result.Table.rows;

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            }
        };

        onMounted(() => {
            
            if(state.isPreview != 'Y'){

                // 지역 조회
                common.getSystemCodeInfo('CODEINFO','CD008000').then(result => state.selectBox.carrerArea = result);

                // 입사지원서 정보 조회
                methods.getEnrollCareer();

                // 변경가능여부
                state.isDisable = true
            }
        });

        return {
            store
            , methods
            , state
            , props
            , recruitCommon
            , common
        }
    }
}
</script>